.home {
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
  text-align: center; /* Center content for small screens */
  max-width: 100%;
  padding: 0 15px; /* Add padding for better alignment */
}

/* Shared Section Styles */
.hero, .comparison {
  display: flex;
  flex-direction: row; /* Default: content on the left, image on the right */
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px;
  gap: 20px;
  max-width: 1200px; /* Limit section width */
  margin: auto; /* Center the sections */
}
.hero {
  margin-top: 20px;
}

.hero-content, .comparison-content {
  flex: 1;
  max-width: 50%; /* Prevent content from exceeding half the section width */
  text-align: center;
}

.hero-image, .comparison-image {
  flex: 1;
  background-size: cover;
  background-position: center;
  height: 400px;
  border-radius: 10px;
  max-width: 45%; /* Adjust image width */
}

/* Specific Hero Section Styles */
.hero {
  background-color: #f5f5f5;
}

.hero-content h1, .comparison-content h1 {
  font-size: 2.5rem; /* Adjust font size for better readability */
  font-weight: bold;
  margin-bottom: 10px;
}

.hero-content p, .comparison-content p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #666;
}

/* Image Backgrounds */
.hero-image {
  background-image: url('../../images/car.jpg'); /* Replace with your hero image */
}

.comparison-image {
  background-image: url('../../images/istockphoto-1410957653-612x612.jpg'); /* Replace with your comparison image */
}

.hero-image, .comparison-image {
  flex: 1 1; /* Allow the image to grow/shrink proportionally */
  background-size: cover;
  background-position: center;
  height: 400px; /* Default height for large screens */
  border-radius: 10px;
  max-width: 45%; /* Default width for large screens */
}

@media (max-width: 1024px) {
  .hero-image, .comparison-image {
    height: 300px; /* Reduce height for medium screens */
    max-width: 60%; /* Increase width to fit smaller layouts */
  }
}



@media (max-width: 768px) {
  .hero-image, .comparison-image {
    background-image: url('../../images/car.jpg'); /* Ensure it applies */
    height: 200px; /* Maintain height for visibility */
  }
}


@media (max-width: 480px) {
  .hero-image, .comparison-image {
    height: 200px; /* Minimized height for mobile devices */
    max-width: 100%; /* Full width for mobile screens */
    border-radius: 8px; /* Slightly smaller corner radius for compact design */
    margin-top: 10px; /* Add some spacing from surrounding elements */
  }
}


  

/* Buttons */
.primary-button, .secondary-button {
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  display: inline-block;
}

.primary-button, .secondary-button {
  background-color: #fff;
  color: #007bff;
  border: 1px solid #007bff;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.primary-button:hover, .secondary-button:hover {
  background-color: #007bff;
  color: #fff;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .hero, .comparison {
    flex-direction: column; /* Stack content and image vertically */
  }

  .hero-content, .comparison-content {
    width: 100%; /* Take up full width */
    text-align: center;
    padding: 20px;
  }

  .hero-image, .comparison-image {
    width: 100%; /* Make images responsive */
    height: 250px; /* Adjust image height */
    margin-top: 20px;
  }

  .hero-content h1, .comparison-content h1 {
    font-size: 1.8rem; /* Reduce font size for smaller screens */
  }

  .hero-content p, .comparison-content p {
    font-size: 1rem; /* Adjust text size */
  }
}

@media (max-width: 480px) {
  .hero-image, .comparison-image {
    height: 200px; /* Further reduce image height for very small screens */
  }

  .primary-button, .secondary-button {
    font-size: 0.9rem; /* Adjust button size for smaller screens */
    padding: 8px 16px;
  }

  .hero-content h1, .comparison-content h1 {
    font-size: 1.5rem; /* Adjust header size for small screens */
  }
}
