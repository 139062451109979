/* src/components/Inventory.css */

.inventory-container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.inventory-container h1 {
  margin-bottom: 60px;
  color: #333;
}

.car-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns on large screens */
  gap: 55px;
  width: 100%;
  max-width: 800px; /* Optional: Limit the total width */
}

@media (max-width: 768px) {
  .car-list {
    grid-template-columns: repeat(2, 1fr); /* Maintain two columns on medium screens */
    max-width: 600px; /* Adjust max-width for medium screens */
  }
}

@media (max-width: 480px) {
  .car-list {
    grid-template-columns: 1fr; /* Single column on small screens */
    max-width: 100%; /* Full width on small screens */
  }
}

/* Loading Spinner and Error Message Styles */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  font-size: 1.5em;
  color: #555;
}

.error-message {
  color: red;
  margin-top: 20px;
  font-size: 1.2em;
}
