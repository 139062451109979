.title-loan {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .title-loan h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .title-loan form {
    display: flex;
    flex-direction: column;
  }
  
  .title-loan label {
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .title-loan input[type="text"],
  .title-loan input[type="email"],
  .title-loan input[type="tel"],
  .title-loan select,
  .title-loan input[type="range"] {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .title-loan .radio-group,
  .title-loan .checkbox-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .title-loan .radio-group input,
  .title-loan .checkbox-group input {
    margin-right: 5px;
  }
  
  .title-loan .message {
    text-align: center;
    color: green;
    margin-bottom: 10px;
  }
  
  .title-loan button {
    margin-top: 20px;
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .title-loan button:hover {
    background-color: #0056b3;
  }
  