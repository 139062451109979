.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #007BFF;
  color: #fff;
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 55px;
}

/* Logo Styling */
.header .logo a {
  color: #fff;
  text-decoration: none;
  font-size: 25px;
  font-weight: bold;
  white-space: nowrap; /* Prevent logo text wrapping */
}

/* Navigation Menu */
.header nav {
  margin-left: auto; /* Push navigation to the right */
}

.header nav ul {
  list-style: none;
  display: flex;
  gap: 20px; /* Add space between menu items */
  margin: 0;
  padding: 0;
}

.header nav ul li {
  display: inline-block;
}

.header nav ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  padding: 5px 10px;
  transition: border-bottom 0.3s ease;
}

.header nav ul li a:hover {
  border-bottom: 2px solid #fff;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .header {
    flex-wrap: wrap; /* Allow wrapping for small screens */
  }

  .header nav ul {
    gap: 10px; /* Reduce gap between menu items */
  }

  .header nav ul li a {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .header nav ul {
    flex-wrap: wrap; /* Stack items if space is limited */
    justify-content: flex-end; /* Ensure menu stays aligned to the right */
  }

  .header nav ul li a {
    font-size: 12px; /* Smaller font size for very small screens */
    padding: 5px;
  }
}
