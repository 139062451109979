/* ApplyFinancing.css */

.apply-financing {
    padding: 30px 20px;
    background-color: #f9f9f9;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .apply-financing h2 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 50px;
    color: #333;
  }

  .apply-financing h3 {
    color: #333;
  }

  .apply-financing p {
    color: #555;
  }
  
  .apply-financing .message {
    text-align: center;
    font-size: 16px;
    margin-bottom: 20px;
    color: #0056b3; /* Red color for errors or success messages */
  }
  
  .apply-financing form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .apply-financing label {
    font-size: 16px;
    color: #555;
  }
  
  .apply-financing input {
    padding: 12px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .apply-financing input:focus {
    border-color: #007BFF;
    outline: none;
    box-shadow: 0 0 5px rgba(232, 33, 39, 0.5);
  }
  
  .apply-financing button {
    padding: 15px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 25px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .apply-financing button:hover {
    background-color: #0056b3;
  }
  
  .responsive-row-name {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  .responsive-row-long {
    display: flex;
    gap: 10px;
  }
  
  .apply-financing label {
    margin-right: 5px;
  }
  

  /* Responsive Design */
  
  @media (max-width: 600px) {
    .apply-financing {
      padding: 40px 10px;
    }
    
  
    .apply-financing h2 {
      font-size: 28px;
    }
  
    .apply-financing button {
      font-size: 16px;
    }
    
  }

  .apply-financing select {
    padding: 12px 15px; /* Match input padding */
    border: 1px solid #ccc; /* Match input border */
    border-radius: 5px; /* Match input border-radius */
    font-size: 16px; /* Match input font size */
    background-color: #fff; /* Match input background color */
    appearance: none; /* Remove default browser styling for dropdowns */
  }
  
  .apply-financing select:focus {
    border-color: #007BFF; /* Match input focus border color */
    outline: none; /* Remove default outline */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Match input focus shadow */
  }
  
  
.responsive-row-long .responsive-input input[name="howLongAtAddressYears"],
.responsive-row-long .responsive-input input[name="howLongAtEmployerYears"] {
    width: 80px; /* Adjust the width to your preference */
}

@media (max-width: 600px) {
  .responsive-row-long .responsive-input input[name="howLongAtAddressYears"],
  .responsive-row-long .responsive-input input[name="howLongAtEmployerYears"] {
      width: 60px; /* Adjust for smaller screens */
  }
}

.section-header {
  font-size: 21px;
  font-weight: bold;
  margin-top: 55px;
}

.line {
  height: 1px;
  background: #ccc;
  margin: 10px 0; /* Add spacing above and below */
  width: 100%; /* Adjust width as needed */
}

.subtitle {
  font-style: italic;
}
  

.large-checkbox {
  transform: scale(1.3); /* Increase the size of the checkbox */
  margin-right: 10px; /* Add some space between the checkbox and the label */
}
