/* Container Styling */
.car-detail-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

/* Back Button Styling */
.back-button {
  align-self: flex-start;
  padding: 8px 16px;
  margin-bottom: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #0056b3;
}

/* Image Styling */
.car-detail-image {
  width: 100%;
  max-width: 600px;
  height: 40vh; /* 40% of the viewport height */
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
  transition: height 0.3s ease;
}

/* Details Section Styling */
.car-details {
  width: 100%;
  text-align: left;
}

.car-details h2 {
  margin-bottom: 15px;
  font-size: 1.8em;
  font-weight: bold;
  color: #333;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
}

.car-details p {
  margin: 8px 0;
  color: #555;
  font-size: 1em;
  line-height: 1.6;
}

.car-details strong {
  color: #333;
}

.car-details .options {
  margin-top: 15px;
  font-size: 0.95em;
  color: #666;
  line-height: 1.5;
}

.car-details .options strong {
  color: #444;
  display: block;
  margin-bottom: 10px;
  font-size: 1.1em;
}

.car-details .options ul {
  list-style-type: disc; /* Use bullet points */
  margin-left: 20px;
  padding-left: 0;
}

.car-details .options ul li {
  margin-bottom: 8px;
  color: #555;
}

/* Action Buttons */
.action-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.edit-button,
.delete-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
  text-decoration: none;
  color: #fff;
  display: inline-block;
  text-align: center;
}

.edit-button {
  background-color: #28a745;
}

.edit-button:hover {
  background-color: #218838;
}

.delete-button {
  background-color: #dc3545;
}

.delete-button:hover {
  background-color: #c82333;
}

.delete-button:disabled {
  background-color: #94c0e6;
  cursor: not-allowed;
}

/* Loading Spinner */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  font-size: 1.5em;
  color: #555;
}

/* Error Message */
.error-message {
  color: red;
  margin-top: 20px;
  font-size: 1.2em;
}

/* Carousel Styles */
.carousel .slide img {
  width: 80% !important; /* Override the default 100% width */
  max-height: 60vh; /* Limit the height to 60% of the viewport */
  object-fit: cover; /* Maintain aspect ratio and crop excess */
  margin: 0 auto; /* Center the image horizontally */
  display: block; /* Ensure proper alignment */
  border-radius: 8px; /* Optional: Add rounded corners */
}

.carousel.carousel-slider .control-arrow.control-prev {
  left: 80px; /* Adjust the distance from the left edge */
}

.carousel.carousel-slider .control-arrow.control-next {
  right: 80px; /* Adjust the distance from the right edge */
}

/* Updated Thumbnail Styles */
.carousel .thumbs {
  display: flex;
  flex-wrap: wrap; /* Allow thumbnails to wrap to the next line */
  justify-content: center; /* Center align thumbnails */
  gap: 5px; /* Add spacing between thumbnails */
  padding: 10px 0; /* Add padding above and below the thumbnails */
  margin: 0 auto; /* Center the entire container */
}

.carousel .thumb {
  flex: 0 0 auto; /* Prevent thumbnails from stretching */
  list-style: none; /* Remove bullet points */
  margin: 0 5px; /* Add spacing between individual thumbnails */
}

.carousel .thumb img {
  width: 70px; /* Set a fixed width for thumbnails */
  height: 50px; /* Set a fixed height for thumbnails */
  object-fit: cover; /* Crop and maintain aspect ratio */
  border-radius: 4px; /* Optional: Add rounded corners */
  transition: transform 0.3s ease, border-color 0.3s ease; /* Add smooth transition for hover */
}

.carousel .thumb img:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
  border: 2px solid #007bff; /* Highlight border on hover */
}



/* Adjustments for Small Screens */
@media (max-width: 768px) {
  .car-detail-image {
    width: 100%;
    height: 30vh;
  }

  .carousel .slide img {
    width: 95% !important;
    max-height: 40vh;
  }

  .carousel.carousel-slider .control-arrow.control-prev,
  .carousel.carousel-slider .control-arrow.control-next {
    left: 40px;
    right: 40px;
  }

  .carousel .thumb img {
    width: 60px; /* Smaller thumbnails for medium screens */
    height: 40px;
  }

  .carousel .thumbs {
    gap: 3px;
    padding: 8px 0;
  }
}

@media (max-width: 480px) {
  .car-detail-image {
    width: 100%;
    height: 25vh;
  }

  .carousel .slide img {
    width: 100% !important;
    max-height: 35vh;
  }

  .carousel.carousel-slider .control-arrow {
    display: none;
  }

  .carousel .thumb img {
    width: 50px; /* Even smaller thumbnails for small screens */
    height: 35px;
  }

  .carousel .thumbs {
    gap: 2px;
    padding: 5px 0;
  }

  .back-button {
    width: 100%;
    text-align: center;
    font-size: 0.8em;
    padding: 5px 10px;
  }

  .car-details h2 {
    font-size: 1.4em;
  }

  .car-details p {
    font-size: 0.9em;
  }
}


/* Responsive Adjustments for Mobile */
@media (max-width: 768px) {
  ul.thumbs.animated {
    transform: none !important;
    transition: none !important;
  }

  ul.thumb.selected {
    transform: none !important;
    transition: none !important;
  }

  ul.thumbs {
    transform: none !important;
    transition: none !important;
  }
  
}

@media (max-width: 480px) {
  ul.thumbs.animated {
    transform: none !important;
    transition: none !important;
    
  }
  ul.thumb.selected {
    transform: none !important;
    transition: none !important;
  }
  ul.thumbs {
    transform: none !important;
    transition: none !important;
  }
  
}
