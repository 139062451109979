/* src/components/Contact/Contact.css */

.contact-container {
    max-width: 800px;
    margin: 0 auto; /* Center the container */
    padding: 40px 20px;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .contact-container h1 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5em;
    color: #2c3e50;
  }
  
  .contact-details {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  .contact-item {
    background-color: #ecf0f1;
    padding: 20px 30px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .contact-item h2 {
    margin-bottom: 10px;
    font-size: 1.5em;
    color: #2980b9;
  }
  
  .contact-item p {
    font-size: 1.1em;
    line-height: 1.6;
  }
  
  @media (min-width: 600px) {
    .contact-details {
      flex-direction: row;
      justify-content: space-between;
    }
    
    .contact-item {
      width: 48%;
    }
  }
  