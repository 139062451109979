/* Footer.css */

.footer {
    background-color: #fff;
    color: #000;
    padding: 20px 20px;
    text-align: center;
  }
  
  .footer p {
    margin: 10px 0;
    font-size: 14px;
  }
  
  .footer .social-links {
    margin: 20px 0;
  }
  
  .footer .social-links a {
    margin: 0 10px;
    color: #000;
    font-size: 18px;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer .social-links a:hover {
    color: #e82127; /* Tesla red on hover */
  }
  
  .footer .links {
    list-style: none;
    padding: 0;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .footer .links li a {
    color: #000;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease;
  }
  
  .footer .links li a:hover {
    color: #e82127;
  }
  
  .footer .copyright {
    font-size: 12px;
    color: #777;
    margin-top: 20px;
  }
  