.car-card-link {
  text-decoration: none;
  color: inherit;
}

.car-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.car-card:hover {
  transform: translateY(-5px);
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.2);
}

.car-image {
  width: 100%; /* Fill the width of the card */
  height: 300px; /* Set a consistent height for all images */
  object-fit: cover; /* Ensures the image covers the area without distortion */
  border-radius: 4px;
  margin-bottom: 12px;
}

.car-info {
  text-align: center;
}

.car-info h3 {
  margin: 8px 0;
  font-size: 1.2em;
  color: #333;
}

.car-info p {
  margin: 4px 0;
  color: #555;
  font-size: 1em;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .car-card {
    padding: 12px; /* Adjust padding for smaller screens */
  }

  .car-image {
    height: 300px; /* Slightly reduce height on smaller screens */
  }
}

@media (max-width: 480px) {
  .car-card {
    padding: 10px;
  }

  .car-image {
    height: 250px; /* Further reduce height for smaller screens */
  }
}

